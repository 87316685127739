import { IconWrapper } from './style';
import type { IconProps } from '../../types';

export const Minus = ({ color, rotation, width = '24px', height = '24px', cursor }: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ? rotation : 0})`}
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill={color ?? 'currentColor'}
        d="M1 2C.447715 2 2e-8 1.55229 0 1-2e-8.447716.447715 5e-7 1 4.8e-7L15 0c.5523-2e-8 1 .447715 1 1 0 .55228-.4477 1-1 1H1z"
        transform="translate(3, 10)"
      />
    </svg>
  </IconWrapper>
);
