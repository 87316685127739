import { IconWrapper } from './style';
import type { IconProps } from '../../types';

export const Leaf = ({ color, rotation, width = '24px', height = '24px', cursor }: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 17 17"
      fill="none"
      transform={`rotate(${rotation ? rotation : 0})`}
    >
      <path
        d="M2.74625 14.249C2.04108 13.5407 1.49042 12.7262 1.09425 11.8055C0.698083 10.8848 0.5 9.933 0.5 8.95C0.5 7.96417 0.688833 6.986 1.0665 6.0155C1.444 5.045 2.05392 4.13858 2.89625 3.29625C3.50525 2.68725 4.26775 2.18558 5.18375 1.79125C6.09975 1.39708 7.13725 1.09683 8.29625 0.890499C9.45525 0.683999 10.7183 0.563749 12.0855 0.529749C13.4528 0.495916 14.8872 0.539833 16.3885 0.6615C16.4962 2.09483 16.533 3.4875 16.499 4.8395C16.465 6.19133 16.3468 7.45183 16.1443 8.621C15.9416 9.79033 15.6458 10.8433 15.2568 11.7797C14.8676 12.7162 14.3666 13.4909 13.7538 14.1037C12.9281 14.9359 12.0451 15.5433 11.1048 15.926C10.1644 16.3087 9.212 16.5 8.2475 16.5C7.23633 16.5 6.24292 16.3019 5.26725 15.9057C4.29158 15.5096 3.45125 14.9573 2.74625 14.249ZM4.9885 14.0845C5.491 14.4 6.02208 14.6314 6.58175 14.7787C7.14142 14.9262 7.6975 15 8.25 15C9.021 15 9.78358 14.8474 10.5378 14.5422C11.2921 14.2371 12.0077 13.7397 12.6845 13.05C12.9833 12.75 13.2863 12.3292 13.5935 11.7875C13.9005 11.2458 14.1661 10.5375 14.3903 9.6625C14.6144 8.7875 14.7846 7.72917 14.9008 6.4875C15.0169 5.24583 15.0398 3.76667 14.9693 2.05C14.1526 2.01667 13.2285 2.00733 12.197 2.022C11.1657 2.03683 10.1368 2.12083 9.1105 2.274C8.08433 2.42717 7.1145 2.66725 6.201 2.99425C5.2875 3.32108 4.54867 3.76658 3.9845 4.33075C3.241 5.07442 2.72433 5.82733 2.4345 6.5895C2.14483 7.35167 2 8.07442 2 8.75775C2 9.70258 2.18433 10.5571 2.553 11.3212C2.9215 12.0852 3.30508 12.6525 3.70375 13.023C4.26275 11.7667 5.04833 10.5612 6.0605 9.40675C7.07267 8.25225 8.35508 7.24492 9.90775 6.38475C8.73325 7.40908 7.72742 8.53408 6.89025 9.75975C6.05308 10.9852 5.41917 12.4268 4.9885 14.0845Z"
        fill={color ?? 'currentColor'}
      />
    </svg>
  </IconWrapper>
);
