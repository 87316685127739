import { type IconProps } from '../../types';
import { IconWrapper } from './style';

export const Edit = ({ color, rotation, width = '24px', height = '24px', cursor }: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      data-testid="edit-icon"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ? rotation : 0})`}
    >
      <path
        d="M14 6L18 10M18.4142 4.41422L19.5858 5.58579C20.3668 6.36684 20.3668 7.63317 19.5858 8.41422L8 20H4V16L15.5858 4.41422C16.3668 3.63317 17.6332 3.63317 18.4142 4.41422Z"
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  </IconWrapper>
);
