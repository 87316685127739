import { IconWrapper } from './style';
import type { IconProps } from '../../types';

export const Mail = ({ color, rotation, width = '24px', height = '24px', cursor }: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ? rotation : 0})`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-1, -0.8)"
      ></path>
      <path
        d="M22 6L12 13L2 6"
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-1, -0.8)"
      ></path>
    </svg>
  </IconWrapper>
);
