import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type SortDir } from '../../../models/SortDir';
import { spacing } from '../../../themes';
import { IconButton } from '../../Input/IconButton';
import { Chevron } from '../../Icons/Chevron';
import { SkeletonLoader } from '../../Feedback/SkeletonLoader';
import HeaderCell from './Cells/HeaderCell';
import {
  AccordionContent,
  LoadingWrapper,
  ScrollWrapper,
  StyledTableRow,
  TableCell,
  TableContent,
  TableHeaderRow,
  TableWrapper,
} from './style';
import TableFooter from './TableFooter';
import {
  type ColumnType,
  type RowType,
  type SortedColumnType,
  type TableProps,
  type TableRowProps,
} from './types';
import calculateCellWidth from './utils/tableHelper';
import { TableEmpty } from './Components/TableEmpty';
import type React from 'react';

export const Table = ({
  columns,
  rows,
  hideDividers,
  isLoading,
  pageSize,
  tableEmpty: emptyTable,
  mode,
  pageLabelTemplate,
  noHeader,
  onSort,
  initialSortedColumn,
  loadMoreIsLoading,
  hasMoreToLoad,
  onLoadMore,
  rowMinWidth,
  alternateBackground,
  ariaDescription,
  lastRowIsSummarize = false,
}: TableProps) => {
  const { t } = useTranslation('', { keyPrefix: 'component.table' });
  const [headers, setHeaders] = useState<ColumnType[] | undefined>(undefined);
  const currentPageSize = useMemo(() => pageSize || 20, [pageSize]);
  const [lastDisplayIndex, setLastDisplayIndex] = useState(0);
  const [displayRows, setDisplayRows] = useState<RowType[]>([]);
  const [tablePosX, setTablePosX] = useState(0);
  const [openAccordionId, setOpenAccordionId] = useState<string | null>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const [sortedColumn, setSortedColumn] = useState<SortedColumnType>({
    sortDirection: undefined,
    fieldName: '',
  });

  const { colspans, totalColSpan } = useMemo(() => {
    const _colspans = calculateCellWidth(columns, displayRows);

    // Add additional 2 colspans if there is accordion content
    const hasAccordionContent = rows.some((row) => {
      return row.accordionContent !== undefined;
    });

    if (hasAccordionContent) {
      _colspans.accordion = 1;
    }

    return {
      colspans: _colspans,
      totalColSpan: Object.keys(_colspans).reduce((acc, key) => acc + _colspans[key], 0),
    };
  }, [columns, displayRows, rows]);

  const clickedRowId = (id: string) => {
    if (openAccordionId === id) {
      setOpenAccordionId(null);
    } else {
      setOpenAccordionId(id);
    }
  };

  useEffect(() => {
    setHeaders([...columns]);
  }, [columns]);

  const onColumnSort = (fieldName: string, initialSortDir?: SortDir) => {
    let newSortDirection: SortDir;

    if (initialSortDir && !onSort) {
      newSortDirection = initialSortDir;
    } else if (sortedColumn.fieldName !== fieldName) {
      newSortDirection = 'ASC';
    } else {
      newSortDirection = sortedColumn.sortDirection === undefined ? 'ASC' : undefined;

      if (sortedColumn.sortDirection === 'ASC') {
        newSortDirection = 'DESC';
      } else if (sortedColumn.sortDirection === 'DESC') {
        newSortDirection = undefined;
      }
    }

    // Default to frontend sorting if onSort callback is not defined
    if (!onSort) {
      let sortedRows;

      if (newSortDirection === undefined) {
        // sort rows by id
        sortedRows = displayRows.sort((a, b) => {
          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }

          return 0;
        });
      } else {
        sortedRows = displayRows.sort((a, b) => {
          const aCell = a.cells.find((cell) => cell.fieldName === fieldName);
          const bCell = b.cells.find((cell) => cell.fieldName === fieldName);

          if (!aCell || !bCell) {
            return 0;
          }

          if (
            (aCell.content as React.JSX.Element).props.children <
            (bCell.content as React.JSX.Element).props.children
          ) {
            return newSortDirection === 'ASC' ? -1 : 1;
          }
          if (
            (aCell.content as React.JSX.Element).props.children >
            (bCell.content as React.JSX.Element).props.children
          ) {
            return newSortDirection === 'ASC' ? 1 : -1;
          }

          return 0;
        });
      }

      setDisplayRows(sortedRows);
    }

    setSortedColumn({ sortDirection: newSortDirection, fieldName: fieldName });

    onSort?.(newSortDirection, fieldName);
  };

  useEffect(() => {
    if (onSort && initialSortedColumn) {
      setSortedColumn(initialSortedColumn);
    } else {
      initialSortedColumn &&
        onColumnSort(initialSortedColumn.fieldName, initialSortedColumn.sortDirection);
    }
  }, [initialSortedColumn]);

  const getRowMarkup = useCallback(
    () => (
      <>
        {!noHeader && headers && (
          <TableHeaderRow totalColumns={totalColSpan}>
            {headers.map((header, i) => {
              const colspan = colspans[header.fieldName];

              return (
                <HeaderCell
                  key={`header-cell-${i}`}
                  name={header.name}
                  subLabel={header.subLabel}
                  colSpan={colspan}
                  tooltip={header.tooltip}
                  onSort={header.sortable ? onColumnSort : undefined}
                  sortDirection={
                    header.fieldName === sortedColumn.fieldName
                      ? sortedColumn.sortDirection
                      : undefined
                  }
                  sortable={header.sortable}
                  justify={header.justify}
                  tablePosX={tablePosX}
                  fieldName={header.fieldName}
                />
              );
            })}
          </TableHeaderRow>
        )}
        {displayRows.map((row, i) => {
          return (
            <TableRow
              key={`table-row-${i}`}
              columns={columns}
              totalColSpan={totalColSpan}
              colSpans={colspans}
              row={row}
              accordionOpen={openAccordionId === row.id}
              clickedRowId={clickedRowId}
              alternateBackground={alternateBackground}
              isSummarize={lastRowIsSummarize && i === displayRows.length - 1}
            />
          );
        })}
      </>
    ),
    [headers, clickedRowId, onColumnSort, tablePosX, displayRows, columns, noHeader],
  );

  const getTableContent = () => {
    if (isLoading) {
      return (
        <LoadingWrapper>
          <SkeletonLoader height={spacing.medium} />
          <SkeletonLoader height={spacing.small} />
          <SkeletonLoader height={spacing.small} />
        </LoadingWrapper>
      );
    }

    if (!rows || rows.length === 0) {
      return emptyTable ? emptyTable : <TableEmpty />;
    }

    return (
      <ScrollWrapper
        count={displayRows.length}
        totalColSpan={totalColSpan}
        rowMinWidth={rowMinWidth}
      >
        {getRowMarkup()}
      </ScrollWrapper>
    );
  };

  const getTableFooter = () => {
    return mode === 'paging' && !isLoading && ((rows && rows.length > 0) || hasMoreToLoad) ? (
      <TableFooter
        loadMoreIsLoading={loadMoreIsLoading}
        noLines={hideDividers || false}
        hasMore={lastDisplayIndex < rows.length || !!hasMoreToLoad}
        loadMore={loadMore}
        hideTotal={true}
        pageLabel={getPageLabel()}
      />
    ) : null;
  };

  const loadMore = () => {
    if (!rows) {
      return;
    }

    if (!onLoadMore) {
      if (lastDisplayIndex < rows.length) {
        const newIndex = lastDisplayIndex + currentPageSize;

        if (newIndex > rows.length) {
          setLastDisplayIndex(rows.length);
        } else {
          setLastDisplayIndex(newIndex);
        }
      }

      return;
    }

    onLoadMore();
  };

  const getPageLabel = () => {
    if (!rows) {
      return '';
    }
    if (pageLabelTemplate) {
      return pageLabelTemplate
        .replace('{{first}}', '1')
        .replace('{{last}}', lastDisplayIndex.toString())
        .replace('{{total}}', rows.length.toString());
    }

    return `1-${lastDisplayIndex} ${t('of')} ${rows.length}`;
  };

  const handleResize = () => {
    if (tableRef.current) {
      setTablePosX(window.innerWidth - tableRef.current.offsetLeft);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (rows) {
      if (!mode || mode === 'scroll') {
        setDisplayRows(rows);

        return;
      }

      if (lastDisplayIndex) {
        setDisplayRows(rows.slice(0, lastDisplayIndex));
      }
    }
  }, [rows, lastDisplayIndex, mode, currentPageSize]);

  useEffect(() => {
    if (!rows) {
      return;
    }
    setLastDisplayIndex(currentPageSize > rows.length ? rows.length : currentPageSize);
  }, [rows, currentPageSize]);

  return (
    <TableWrapper aria-description={ariaDescription}>
      <TableContent ref={tableRef}>{getTableContent()}</TableContent>
      <>{getTableFooter()}</>
    </TableWrapper>
  );
};

const TableRow = ({
  columns,
  row,
  accordionOpen,
  clickedRowId,
  totalColSpan,
  colSpans,
  alternateBackground,
  isSummarize = false,
}: TableRowProps) => {
  const hasAccordionContent = row.accordionContent !== undefined;

  const toggleAccordionOpen = () => {
    if (clickedRowId) {
      clickedRowId(row.id);
    }
  };

  return (
    <>
      <StyledTableRow
        data-testid="table-row"
        hasAccordionContent={hasAccordionContent}
        alternateBackground={alternateBackground}
        totalColSpan={totalColSpan}
        onClick={() => {
          if (hasAccordionContent) {
            toggleAccordionOpen();
          }
        }}
        isSummarize={isSummarize}
      >
        {row.singleCell ? (
          <TableCell key={`row-0`} colSpan={totalColSpan}>
            {row.cells[0].content}
          </TableCell>
        ) : (
          <>
            {columns.map((column, j) => {
              let cell = row.cells.find((cell) => cell.fieldName === column.fieldName);

              if (!cell && row.cells.length > j) {
                cell = row.cells[j];
              }

              if (!cell) {
                return <Fragment key={`cell-${j}`} />;
              }

              return (
                <TableCell
                  colSpan={colSpans?.[column.fieldName]}
                  key={`cell-${j}`}
                  justify={column.justify}
                >
                  {cell.content}
                </TableCell>
              );
            })}
            {hasAccordionContent && (
              <TableCell colSpan={colSpans?.accordion} justify="center">
                <IconButton variant="secondary" onClick={toggleAccordionOpen}>
                  <Chevron rotation={accordionOpen ? 180 : 0} />
                </IconButton>
              </TableCell>
            )}
          </>
        )}
      </StyledTableRow>

      {hasAccordionContent && accordionOpen && (
        <AccordionContent>{row.accordionContent}</AccordionContent>
      )}
    </>
  );
};
