import { type PictogramIconProps } from './types';

// materials_05
export const Particles = ({ color }: PictogramIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2822 24.1084C31.8345 24.1084 32.2822 24.5561 32.2822 25.1084C32.2822 29.0621 35.2415 32.1387 38.7397 32.1387C39.292 32.1387 39.7397 32.5865 39.7397 33.1387C39.7397 33.691 39.292 34.1387 38.7397 34.1387C34.0001 34.1387 30.2822 30.0246 30.2822 25.1084C30.2822 24.5561 30.7299 24.1084 31.2822 24.1084Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.3117 13.5107C32.8902 13.5107 27.6846 18.7164 27.6846 25.1378C27.6846 31.5593 32.8902 36.7649 39.3117 36.7649C45.7331 36.7649 50.9387 31.5593 50.9387 25.1378C50.9387 18.7164 45.7331 13.5107 39.3117 13.5107ZM24.6846 25.1378C24.6846 17.0595 31.2333 10.5107 39.3117 10.5107C47.39 10.5107 53.9387 17.0595 53.9387 25.1378C53.9387 33.2161 47.39 39.7649 39.3117 39.7649C31.2333 39.7649 24.6846 33.2161 24.6846 25.1378Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.6572 24.1084C65.2095 24.1084 65.6572 24.5561 65.6572 25.1084C65.6572 29.0621 68.6165 32.1387 72.1147 32.1387C72.667 32.1387 73.1147 32.5865 73.1147 33.1387C73.1147 33.691 72.667 34.1387 72.1147 34.1387C67.3751 34.1387 63.6572 30.0246 63.6572 25.1084C63.6572 24.5561 64.1049 24.1084 64.6572 24.1084Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.6876 13.5107C66.2662 13.5107 61.0605 18.7164 61.0605 25.1378C61.0605 31.5593 66.2662 36.7649 72.6876 36.7649C79.1091 36.7649 84.3147 31.5593 84.3147 25.1378C84.3147 18.7164 79.1091 13.5107 72.6876 13.5107ZM58.0605 25.1378C58.0605 17.0595 64.6093 10.5107 72.6876 10.5107C80.7659 10.5107 87.3147 17.0595 87.3147 25.1378C87.3147 33.2161 80.7659 39.7649 72.6876 39.7649C64.6093 39.7649 58.0605 33.2161 58.0605 25.1378Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5889 54.9658C15.1412 54.9658 15.5889 55.4135 15.5889 55.9658C15.5889 59.9195 18.5482 62.9962 22.0463 62.9962C22.5986 62.9962 23.0463 63.4439 23.0463 63.9962C23.0463 64.5484 22.5986 64.9962 22.0463 64.9962C17.3067 64.9962 13.5889 60.882 13.5889 55.9658C13.5889 55.4135 14.0366 54.9658 14.5889 54.9658Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6193 44.368C16.1978 44.368 10.9922 49.5736 10.9922 55.9951C10.9922 62.4165 16.1978 67.6222 22.6193 67.6222C29.0407 67.6222 34.2464 62.4165 34.2464 55.9951C34.2464 49.5736 29.0407 44.368 22.6193 44.368ZM7.99219 55.9951C7.99219 47.9167 14.541 41.368 22.6193 41.368C30.6976 41.368 37.2464 47.9167 37.2464 55.9951C37.2464 64.0734 30.6976 70.6222 22.6193 70.6222C14.541 70.6222 7.99219 64.0734 7.99219 55.9951Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.9648 54.9658C48.5171 54.9658 48.9648 55.4135 48.9648 55.9658C48.9648 59.9195 51.9241 62.9962 55.4223 62.9962C55.9746 62.9962 56.4223 63.4439 56.4223 63.9962C56.4223 64.5484 55.9746 64.9962 55.4223 64.9962C50.6827 64.9962 46.9648 60.882 46.9648 55.9658C46.9648 55.4135 47.4126 54.9658 47.9648 54.9658Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.995 44.368C49.5735 44.368 44.3679 49.5736 44.3679 55.9951C44.3679 62.4165 49.5735 67.6222 55.995 67.6222C62.4165 67.6222 67.6221 62.4165 67.6221 55.9951C67.6221 49.5736 62.4165 44.368 55.995 44.368ZM41.3679 55.9951C41.3679 47.9167 47.9167 41.368 55.995 41.368C64.0733 41.368 70.6221 47.9167 70.6221 55.9951C70.6221 64.0734 64.0733 70.6222 55.995 70.6222C47.9167 70.6222 41.3679 64.0734 41.3679 55.9951Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.3506 54.9658C81.9029 54.9658 82.3506 55.4135 82.3506 55.9658C82.3506 59.9195 85.3099 62.9962 88.808 62.9962C89.3603 62.9962 89.808 63.4439 89.808 63.9962C89.808 64.5484 89.3603 64.9962 88.808 64.9962C84.0684 64.9962 80.3506 60.882 80.3506 55.9658C80.3506 55.4135 80.7983 54.9658 81.3506 54.9658Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.1795 44.5735C80.8739 45.7878 76.7465 51.8838 77.9608 58.1895C79.1751 64.4951 85.2712 68.6224 91.5768 67.4081C97.8824 66.1939 102.01 60.0978 100.795 53.7922C99.5812 47.4866 93.4851 43.3592 87.1795 44.5735ZM75.0149 58.7567C73.4874 50.8242 78.6796 43.1552 86.6122 41.6276C94.5448 40.1001 102.214 45.2923 103.741 53.2249C105.269 61.1575 100.077 68.8265 92.144 70.354C84.2115 71.8816 76.5425 66.6893 75.0149 58.7567Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2822 85.8223C31.8345 85.8223 32.2822 86.27 32.2822 86.8223C32.2822 90.776 35.2415 93.8526 38.7397 93.8526C39.292 93.8526 39.7397 94.3003 39.7397 94.8526C39.7397 95.4049 39.292 95.8526 38.7397 95.8526C34.0001 95.8526 30.2822 91.7385 30.2822 86.8223C30.2822 86.27 30.7299 85.8223 31.2822 85.8223Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.3117 75.2246C32.8902 75.2246 27.6846 80.4302 27.6846 86.8517C27.6846 93.2732 32.8902 98.4788 39.3117 98.4788C45.7331 98.4788 50.9387 93.2732 50.9387 86.8517C50.9387 80.4302 45.7331 75.2246 39.3117 75.2246ZM24.6846 86.8517C24.6846 78.7734 31.2333 72.2246 39.3117 72.2246C47.39 72.2246 53.9387 78.7734 53.9387 86.8517C53.9387 94.93 47.39 101.479 39.3117 101.479C31.2333 101.479 24.6846 94.93 24.6846 86.8517Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.6572 85.8223C65.2095 85.8223 65.6572 86.27 65.6572 86.8223C65.6572 90.776 68.6165 93.8526 72.1147 93.8526C72.667 93.8526 73.1147 94.3003 73.1147 94.8526C73.1147 95.4049 72.667 95.8526 72.1147 95.8526C67.3751 95.8526 63.6572 91.7385 63.6572 86.8223C63.6572 86.27 64.1049 85.8223 64.6572 85.8223Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.6876 75.2246C66.2662 75.2246 61.0605 80.4302 61.0605 86.8517C61.0605 93.2732 66.2662 98.4788 72.6876 98.4788C79.1091 98.4788 84.3147 93.2732 84.3147 86.8517C84.3147 80.4302 79.1091 75.2246 72.6876 75.2246ZM58.0605 86.8517C58.0605 78.7734 64.6093 72.2246 72.6876 72.2246C80.7659 72.2246 87.3147 78.7734 87.3147 86.8517C87.3147 94.93 80.7659 101.479 72.6876 101.479C64.6093 101.479 58.0605 94.93 58.0605 86.8517Z"
        fill={color ?? 'currentColor'}
      />
    </svg>
  );
};
