import { type PictogramIconProps } from './types';

// 2022_containers_01
export const TrashCan = ({ color }: PictogramIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.6257 85.1514C65.4082 85.1514 61.9891 88.5704 61.9891 92.788C61.9891 97.0056 65.4082 100.425 69.6257 100.425C73.8433 100.425 77.2623 97.0056 77.2623 92.788C77.2623 88.5704 73.8433 85.1514 69.6257 85.1514ZM58.9891 92.788C58.9891 86.9136 63.7513 82.1514 69.6257 82.1514C75.5002 82.1514 80.2623 86.9136 80.2623 92.788C80.2623 98.6624 75.5002 103.425 69.6257 103.425C63.7513 103.425 58.9891 98.6624 58.9891 92.788Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.3994 99.4475L31.2635 21.4558L34.2632 21.4121L35.3991 99.4042C35.4072 99.9682 35.8761 100.435 36.4477 100.435H68.8258V103.435H36.4477C34.2339 103.435 32.431 101.649 32.3994 99.4475C32.3994 99.4474 32.3994 99.4476 32.3994 99.4475Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5664 14.8857C28.5664 14.0573 29.238 13.3857 30.0664 13.3857H79.5819C80.4103 13.3857 81.0819 14.0573 81.0819 14.8857V21.6616C81.0819 22.4901 80.4103 23.1616 79.5819 23.1616H30.0664C29.238 23.1616 28.5664 22.4901 28.5664 21.6616V14.8857ZM31.5664 16.3857V20.1616H78.0819V16.3857H31.5664Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.3135 10.0654C35.3135 9.237 35.985 8.56543 36.8135 8.56543H72.8364C73.6648 8.56543 74.3364 9.237 74.3364 10.0654V14.8263C74.3364 15.6548 73.6648 16.3263 72.8364 16.3263H36.8135C35.985 16.3263 35.3135 15.6548 35.3135 14.8263V10.0654ZM38.3135 11.5654V13.3263H71.3364V11.5654H38.3135Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.3555 12.3867C71.3555 11.5583 72.027 10.8867 72.8555 10.8867H88.926C89.4594 10.8867 89.9526 11.1699 90.2215 11.6306C90.4904 12.0912 90.4944 12.66 90.2321 13.1244L77.4507 35.7536C77.0433 36.4749 76.1283 36.7294 75.407 36.3219C74.6856 35.9145 74.4312 34.9995 74.8386 34.2782L86.3561 13.8867H72.8555C72.027 13.8867 71.3555 13.2151 71.3555 12.3867Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.3728 22.0594L74.5305 84.4353L71.5361 84.2509L75.3784 21.875L78.3728 22.0594Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.9023 21.1689C45.7308 21.1689 46.4023 21.8405 46.4023 22.6689V35.0157C46.4023 35.8441 45.7308 36.5157 44.9023 36.5157C44.0739 36.5157 43.4023 35.8441 43.4023 35.0157V22.6689C43.4023 21.8405 44.0739 21.1689 44.9023 21.1689Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.7461 21.2383C65.5745 21.2383 66.2461 21.9099 66.2461 22.7383V35.085C66.2461 35.9135 65.5745 36.585 64.7461 36.585C63.9177 36.585 63.2461 35.9135 63.2461 35.085V22.7383C63.2461 21.9099 63.9177 21.2383 64.7461 21.2383Z"
        fill={color ?? 'currentColor'}
      />
    </svg>
  );
};
