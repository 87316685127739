import { useNavigate } from 'react-router-dom';
import { useOneSpa } from '../hooks/useOneSpa';
import { getStartUrl } from '../utils/environment';
import LogoIcon from './LogoIcon';
import {
  CopyText,
  InnerFooter,
  Link,
  LinkWrapper,
  LowerFooter,
  StyledFooter,
  UpperFooter,
} from './styles';
import { useFlag } from '..';

type FooterProps = {
  text: { support: string; privacyInformation: string; releaseNotes: string };
  /** defaults to 48px */
  marginTop?: string;
};

export const Footer = ({ text }: FooterProps) => {
  //Remove this when all the projects imports the language files in i18next.init instead to avoid the key flickering
  const supportTextIsLoaded = text.support !== 'footer.customer.support';
  const privacyInformationIsLoaded = text.privacyInformation !== 'footer.privacy.information';
  const releaseNotesTextIsLoaded = text.releaseNotes !== 'footer.release.notes';
  const isOneSpa = useOneSpa();
  const navigate = useNavigate();
  const shouldSeeReleaseNotes = useFlag('release-notes-page');

  const onContactSupportClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (isOneSpa) {
      e.preventDefault();
      navigate('/support');
    }
  };

  const onReleaseNotesClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (isOneSpa) {
      e.preventDefault();
      navigate('/support/release-notes');
    }
  };

  return (
    <StyledFooter>
      <UpperFooter>
        <InnerFooter>
          <LogoIcon />
          <LinkWrapper>
            <Link onClick={onContactSupportClick} href={`${getStartUrl(isOneSpa)}support`}>
              {supportTextIsLoaded ? text.support : ''}
            </Link>
            {shouldSeeReleaseNotes && (
              <Link
                onClick={onReleaseNotesClick}
                href={`${getStartUrl(isOneSpa)}support/release-notes`}
              >
                {releaseNotesTextIsLoaded ? text.releaseNotes : ''}
              </Link>
            )}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.stenarecycling.com/privacy/privacy-information/"
            >
              {privacyInformationIsLoaded ? text.privacyInformation : ''}
            </Link>
          </LinkWrapper>
        </InnerFooter>
      </UpperFooter>
      <LowerFooter>
        <InnerFooter>
          <CopyText>Copyright © Stena Recycling AB</CopyText>
        </InnerFooter>
      </LowerFooter>
    </StyledFooter>
  );
};
