import { type PictogramIconProps } from './types';

// vehicles_12
export const Ship = ({ color }: PictogramIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M87.5245 71.9139C95.9767 65.0607 105 49.0701 105 49.0701H74.2751H36.1259H7V52.2682C7 52.2682 8.59907 69.6295 15.6807 73.9698"
        stroke="#657B6C"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1934 48.3847L20.7062 40.0468H70.1631L76.902 48.3847"
        stroke="#657B6C"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1611 39.8179L26.8744 33.1932H61.3686L65.8231 39.7037"
        stroke="#657B6C"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.4922 23.7132C45.4922 22.7669 46.2593 21.9999 47.2055 21.9999H55.0866C56.0328 21.9999 56.7999 22.7669 56.7999 23.7132V32.1654C56.7999 33.1116 56.0328 33.8787 55.0866 33.8787C54.1404 33.8787 53.3733 33.1116 53.3733 32.1654V25.4265H48.9188V32.2796C48.9188 33.2258 48.1517 33.9929 47.2055 33.9929C46.2593 33.9929 45.4922 33.2258 45.4922 32.2796V23.7132Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5576 23.7132C31.5576 22.7669 32.3247 21.9999 33.2709 21.9999H41.0378C41.984 21.9999 42.7511 22.7669 42.7511 23.7132V32.1654C42.7511 33.1116 41.984 33.8787 41.0378 33.8787C40.0916 33.8787 39.3245 33.1116 39.3245 32.1654V25.4265H34.9842V32.2796C34.9842 33.2258 34.2171 33.9929 33.2709 33.9929C32.3247 33.9929 31.5576 33.2258 31.5576 32.2796V23.7132Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3633 89.1604C20.3633 88.2142 21.1303 87.4471 22.0766 87.4471H81.8132C82.7594 87.4471 83.5264 88.2142 83.5264 89.1604C83.5264 90.1067 82.7594 90.8737 81.8132 90.8737H22.0766C21.1303 90.8737 20.3633 90.1067 20.3633 89.1604Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.37012 82.0794C8.37012 81.1332 9.13718 80.3661 10.0834 80.3661H93.806C94.7522 80.3661 95.5193 81.1332 95.5193 82.0794C95.5193 83.0256 94.7522 83.7927 93.806 83.7927H10.0834C9.13718 83.7927 8.37012 83.0256 8.37012 82.0794Z"
        fill={color ?? 'currentColor'}
      />
    </svg>
  );
};
