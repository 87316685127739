import { type IconProps } from '../../types';
import { IconWrapper } from './style';

export const Check = ({ color, rotation, width = '24px', height = '24px', cursor }: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ? rotation : 0})`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6247 0.219168C16.056 0.564178 16.1259 1.19347 15.7809 1.62473L7.78089 11.6247C7.61305 11.8345 7.36784 11.968 7.10051 11.995C6.83319 12.022 6.56625 11.9403 6.35984 11.7683L0.359841 6.76826C-0.0644363 6.41469 -0.12176 5.78413 0.231804 5.35985C0.585368 4.93558 1.21593 4.87825 1.64021 5.23182L6.85647 9.5787L14.2192 0.375342C14.5642 -0.0559202 15.1935 -0.125842 15.6247 0.219168Z"
        fill={color ?? 'currentColor'}
        transform="translate(2, 4)"
      />
    </svg>
  </IconWrapper>
);
