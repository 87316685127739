import { type PictogramIconProps } from './types';

// electronics_02
export const Monitor = ({ color }: PictogramIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6484 23.8026C10.6484 19.4186 14.1842 15.8828 18.5682 15.8828H93.3336C97.7176 15.8828 101.253 19.4186 101.253 23.8026V77.531C101.253 81.9149 97.7176 85.4507 93.3336 85.4507H18.5682C14.1842 85.4507 10.6484 81.9149 10.6484 77.531V23.8026ZM18.5682 18.8828C15.8411 18.8828 13.6484 21.0754 13.6484 23.8026V77.531C13.6484 80.2581 15.8411 82.4507 18.5682 82.4507H93.3336C96.0607 82.4507 98.2534 80.2581 98.2534 77.531V23.8026C98.2534 21.0754 96.0607 18.8828 93.3336 18.8828H18.5682Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1611 24.3955C18.1611 23.8432 18.6088 23.3955 19.1611 23.3955H92.7414C93.2937 23.3955 93.7414 23.8432 93.7414 24.3955V70.3214C93.7414 70.8737 93.2937 71.3214 92.7414 71.3214H19.1611C18.6088 71.3214 18.1611 70.8737 18.1611 70.3214V24.3955ZM20.1611 25.3955V69.3214H91.7414V25.3955H20.1611Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5625 89.4814C33.5625 88.653 34.2341 87.9814 35.0625 87.9814H76.939C77.7675 87.9814 78.439 88.653 78.439 89.4814V94.6173C78.439 95.4457 77.7675 96.1173 76.939 96.1173H35.0625C34.2341 96.1173 33.5625 95.4457 33.5625 94.6173V89.4814ZM36.5625 90.9814V93.1173H75.439V90.9814H36.5625Z"
        fill={color ?? 'currentColor'}
      />
    </svg>
  );
};
