// Custom hook to access the partner trees, all DW keys, and loading state from the context
import { useContext, useMemo, useCallback } from 'react';
import { type PartnerResItem, type PartnerTreeNodeV2 } from '@stenarecycling/customer-portal-types';
import { useProfile } from '../Profile';
import { LocationsContext } from './LocationsProvider';
import {
  filterPartnersTreeOnConfidential,
  filterPartnersTreeOnService,
  getDwkeysFromPartnerTrees,
} from './utils';

export const useLocations = (
  filterServiceName?: 'services.order.pickup.name' | 'services.follow.up.name',
  filterConfidential?: boolean,
) => {
  const context = useContext(LocationsContext);
  const [profile] = useProfile();
  const locationsById = useMemo(() => {
    const addRecursive = (
      node: PartnerTreeNodeV2,
      acc: Record<string, PartnerResItem | undefined>,
    ) => {
      acc[node.item.dwKey] = node.item;
      if (node.children.length > 0) {
        for (const child of node.children) {
          addRecursive(child, acc);
        }
      }
    };

    if (context?.partnerTrees) {
      return context.partnerTrees.reduce<Record<string, PartnerResItem | undefined>>(
        (acc, curr) => {
          addRecursive(curr, acc);

          return acc;
        },
        {},
      );
    }

    return {};
  }, [context?.partnerTrees]);

  const filteredPartnerTrees = useMemo(() => {
    let partnerTrees = context?.partnerTrees ?? [];

    // Filter the partner trees on the service name if provided
    if (filterServiceName && context?.partnerTrees && profile?.permissions) {
      partnerTrees = filterPartnersTreeOnService({
        serviceName: filterServiceName,
        partnerTrees: context.partnerTrees,
        permissions: profile.permissions,
      });
    }

    // Filter the partner trees on the confidential flag if provided
    if (filterConfidential) {
      partnerTrees = filterPartnersTreeOnConfidential({
        partnerTrees,
      });
    }

    return partnerTrees;
  }, [context?.partnerTrees, filterServiceName, profile?.permissions, filterConfidential]);

  const filteredAllDwKeys = useMemo(() => {
    return getDwkeysFromPartnerTrees(filteredPartnerTrees);
  }, [filteredPartnerTrees]);

  const findLocation = useCallback(
    (dwKey: string) => {
      let found: PartnerResItem | undefined;

      filteredPartnerTrees.forEach((partnerTree) => {
        if (found) {
          return;
        }
        found = findRecursive(partnerTree, dwKey);
      });

      return found;
    },
    [filteredPartnerTrees],
  );

  if (context === null) {
    throw Error('Wrap your app with `LocationsProvider`');
  } else {
    return {
      ...context,
      partnerTrees: filteredPartnerTrees,
      allDwKeys: filteredAllDwKeys,
      findLocation,
      locationsById,
    };
  }
};

const findRecursive = (node: PartnerTreeNodeV2, dwKey: string): PartnerResItem | undefined => {
  if (node.item.dwKey === dwKey) {
    return node.item;
  }

  if (node.children.length > 0) {
    for (const child of node.children) {
      const found = findRecursive(child, dwKey);

      if (found) {
        return found;
      }
    }
  }

  return undefined;
};
