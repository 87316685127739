import { type IconProps } from '../../types';
import { IconWrapper } from './style';

export const Delete = ({ color, rotation, width = '24px', height = '24px', cursor }: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ? rotation : 0})`}
    >
      <path
        id="Vector 3"
        d="M5 20V6H19V20C19 21.1046 18.1046 22 17 22H7C5.89543 22 5 21.1046 5 20Z"
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
      />
      <path
        id="Vector 4"
        d="M3 6L21 6"
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        id="Vector 5"
        d="M9 3V6H15V3H9Z"
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        id="Rectangle 435"
        x="9"
        y="9"
        width="1.5"
        height="10"
        rx="0.75"
        fill={color ?? 'currentColor'}
      />
      <rect
        id="Rectangle 436"
        x="13"
        y="9"
        width="1.5"
        height="10"
        rx="0.75"
        fill={color ?? 'currentColor'}
      />
    </svg>
  </IconWrapper>
);
